#informationText {
  font-size: 22px;
}

.centeredText {
  text-align: center;
  padding-top: 10px;
}

.block-text {
  margin: auto;
  width: 80%;
}

.phonenumber {
  text-decoration: none;
}
#informationTextLinks {
  font-size: 18px;
}

.linkLogosPage {
  width: 30px;
  height: 30px;
}

.backToDiscoverLink {
  text-decoration: none;
  color: #2f343f;
}

.tableCenter {
  font-size: 18px;
}

.hoursTable {
  padding: 5px;
}

#days {
  font-size: 18px;
}

#hours {
  font-size: 18px;
}

.table td {
  padding: 10px;
}

.table th {
  padding: 10px;
}

.modal_Image {
  width: 250px;
  height: 250px;
}

.promotionImage {
  width: 200px;
  height: 200px;
  margin: 10px;
}

.business_logo {
  width: 250px;
  height: 250px;
}

.discoverDiv {
  padding-bottom: 50px;
}

.item {
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.businessHeaderLink {
  text-decoration: underline;
  color: #2f343f;
}

.business_image {
  width: 175px;
  height: 175px;
  padding-bottom: 10px;
}

.businessLinkPic {
  display: inline-block;
  vertical-align: top;
}
.businessLinkText {
  display: inline-block;
}

.linkLogos {
  width: 25px;
  height: 25px;
}

.businessLinks {
  text-decoration: none;
  color: #2f343f;
  padding-left: 5px;
}

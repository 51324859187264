#businessNameLarge {
  font-size: 50px;
}

.sliding-image {
  width: 250px;
  height: 250px;
}

.promotionImage {
  object-fit: cover;
  width: 250px;
  height: 250px;
  margin: 10px;
}

#header {
  text-align: center;
}

h2 {
  text-align: center;
}

.center {
  text-align: center;
  margin-bottom: -10px;
}
